import _ from 'underscore'
import React, {useMemo, useState} from 'react'
import Dropzone from 'react-dropzone'
import {confirmAlert} from 'react-confirm-alert'
import Lightbox from 'react-image-lightbox'
import ReactLoading from 'react-loading'

import close from 'images/close.png'
import useSessionStore from 'store/SessionStore'
import {useUser} from 'context/UserProvider'

import 'react-confirm-alert/src/react-confirm-alert.css'

const initial_state = {
  userId: null,
  lbOpen: false,
  photoIndex: 0,
}

const ProfileCarSection = ({user, profile}) => {
  const {user: currentUser, setUser} = useUser()
  const {
    isProcessing,
    setProcessingRequest,
    uploadProfileImageRequest,
    deleteProfileImageRequest,
  } = useSessionStore()

  const [state, setState] = useState(initial_state)

  const uploadImage = (files, imageType) => {
    setProcessingRequest()

    const fileObj = files[0]
    if (fileObj) {
      var FR = new FileReader()

      FR.addEventListener('load', async (e) => {
        const res = await uploadProfileImageRequest(imageType, e.target.result)
        if (res) {
          setUser(res.data)
        }
      })

      FR.readAsDataURL(fileObj)
    }
  }

  const deleteImage = (imageId) => {
    confirmAlert({
      title: 'Alert!',
      message: 'Are you sure you want to delete this image?',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            const res = await deleteProfileImageRequest(imageId)
            if (res) {
              setUser(res.data)
            }
          },
        },
        {
          label: 'No',
          onClick: () => console.log('canceled'),
        },
      ],
    })
  }

  const carImages = () => {
    const carImages = profile?.relationships?.images?.filter((img) => {
      return img?.attributes?.image_type === 'car'
    })

    return carImages || []
  }

  const profileImagesArr = useMemo(() => {
    const arr = carImages()
    return _.pluck(_.pluck(arr, 'attributes'), 'url')
  }, [profile])

  const onCancel = () => {
    setState({
      ...state,
      files: [],
    })
  }

  const renderCarThumbs = () => {
    const car_images = carImages()
    return car_images.map((img, index) => (
      <div className="imgWrapper" key={index}>
        <img
          src={img.attributes.url}
          className="responsive-img uploadPic"
          alt=""
          onClick={() => setState({...state, lbOpen: true})}
        />

        {user?.id === currentUser?.id && (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            href="javascript:void(0)"
            className="removeImg"
            onClick={() => deleteImage(img?.id)}>
            <img src={close} alt="" className="close-icon" />
          </a>
        )}
      </div>
    ))
  }

  const userInfo = profile ? profile.attributes : null
  const {lbOpen, photoIndex} = state

  return (
    <div className="profile-car-section">
      <div className="row">
        <div className="col s12 l6 m6">
          <h5>Car Information</h5>
          <table className="table table-user-information">
            <tbody>
              <tr>
                <td className="info-label">
                  <b>Make</b>
                </td>
                <td className="info-val">{userInfo?.car_make}</td>
              </tr>
              <tr>
                <td className="info-label">
                  <b>Model</b>
                </td>
                <td className="info-val">{userInfo?.car_model}</td>
              </tr>
              <tr>
                <td className="info-label">
                  <b>Color</b>
                </td>
                <td className="info-val">{userInfo?.car_color}</td>
              </tr>
              <tr>
                <td className="info-label">
                  <b>Year</b>
                </td>
                <td className="info-val">{userInfo?.car_year}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="car-profile-images">{renderCarThumbs()}</div>
      <div className="bubble-container">
        {isProcessing && (
          <ReactLoading
            type="bubbles"
            color="#3399ff"
            height="100px"
            width="100px"
          />
        )}
      </div>
      {currentUser?.id === user?.id && (
        <div className="image image-dash mt20 l6 m6 col" style={{padding: 0}}>
          <Dropzone
            onDrop={(files) => uploadImage(files, 'car')}
            onFileDialogCancel={() => onCancel()}
            className="dropzone">
            {({getRootProps, getInputProps}) => (
              <section className="dropzone">
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div>
                    Try dropping image here, or click to select image to upload.
                  </div>
                </div>
              </section>
            )}
          </Dropzone>
        </div>
      )}
      {lbOpen && (
        <Lightbox
          mainSrc={profileImagesArr[photoIndex]}
          nextSrc={profileImagesArr[(photoIndex + 1) % profileImagesArr.length]}
          prevSrc={
            profileImagesArr[
              (photoIndex + profileImagesArr.length - 1) %
                profileImagesArr.length
            ]
          }
          onCloseRequest={() => setState({...state, lbOpen: false})}
          onMovePrevRequest={() =>
            setState({
              ...state,
              photoIndex:
                (photoIndex + profileImagesArr.length - 1) %
                profileImagesArr.length,
            })
          }
          onMoveNextRequest={() =>
            setState({
              ...state,
              photoIndex: (photoIndex + 1) % profileImagesArr.length,
            })
          }
        />
      )}
    </div>
  )
}

export default ProfileCarSection
